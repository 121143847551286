* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #ffffff;
  --black: #000000;
  --red: #c01f2e;
  --light-red: #fc8e8e;
  --dark-red: #8f101c;
  --teal: #19716a;
  --heading: #002e36;
  --small-nav: #081e21;
  --dark-teal: #081e21;
  --footer: #002931;
  --light-gray: #efeff0;
  --med-gray: #d9d9d9; 
  --dark-gray: #4040429c;
}

html {
  font-size: 62.5%;
}

html::-webkit-scrollbar {
  background-color: var(--white);
}

html::-webkit-scrollbar-thumb {
  background-color: var(--med-gray);
}

.screen-reader-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

body {
  font-family: "Inter", sans-serif;
  width: 100%;
  overflow-x: hidden;
}

.page-container {
  animation-duration: 1.5s;
  animation-delay: 0s;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
}

.staff-text-link {
  color: var(--heading);
  display: block;
  margin-top: 0.5rem;
  font-weight: 500;
}

.hero-h1,
.section-h1,
.lawyer-preview-h2,
.lawyer-h1 {
  font-family: 'Libre Caslon Text', serif;
}

.nav-ul-hamburger {
  display: none;
}

.lawyer-bio .red-button {
  display: none;
}

.small-h2 {
  color: var(--red);
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;
  animation-duration: 2s;
  animation-delay: 1s;
}

.red-button .download-two {
  display: none;
}

.news-copy .red-button {
  display: none;
}

.news-copy .news-header-section .red-button {
  display: block;
}

.section-h1 {
  color: var(--dark-teal);
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 3rem;
  animation-duration: 1s;
  animation-delay: 0.2s;
}

.user-message {
  color: var(--dark-gray);
  font-size: 2.4rem;
  text-align: center;
  margin: 5rem auto 12rem;
}

/* Secondary Pages Banner */

.secondary-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 55dvh;
  margin-top: 50px;
}

.secondary-h1 {
  color: var(--white);
  background-color: var(--red);
  text-transform: uppercase;
  padding: 0.5rem 2rem;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  margin-bottom: 3rem;
  text-align: center;
  line-height: 1.5;
}

.access-h2 {
  color: var(--red);
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 3rem;
  text-transform: uppercase;
}

.access-h3 {
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 3rem;
  text-transform: uppercase;
  margin-top: 4rem;
}

.access-h4 {
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 3rem;
  text-decoration: underline;
  font-weight: 500;
}

.secondary-p {
  color: var(--white);
  font-size: 2.2rem;
  font-weight: 300;
  width: 50%;
  text-align: center;
  line-height: 4rem;
}

.secondary-link {
  color: var(--white);
  cursor: pointer;
  margin: 2rem auto 0;
}

.banner-icon {
  font-size: 1.8rem;
}

.secondary-link:hover {
  animation: moveUpDown 1.3s infinite;
}

#team,
#insights,
#practiceone,
#practicetwo,
#practicethree,
#article,
#dashboard,
#admin-team,
#admin-team-edit,
#admin-edit,
#article-edit,
#hours-edit,
#headers-edit,
#admin-article-edit,
#disclaimer,
#copyright {
  margin: 0 auto 10rem;
  width: 70%;
  padding-top: 4rem;
}

#admin-team-edit, #admin-article-edit, #hours-edit, #headers-edit, #admin-edit, #article-edit {
  margin-top: 50px;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  grid-gap: 50px; /* Adjust the gap between items as needed */
}

/* Lawyer Preview */

.lawyer-preview-container {
  width: 100%;
}

.ham-link {
  display: none;
}


.lawyer-preview-img {
  cursor: pointer;
  width: 100%;
  margin-bottom: 1rem;
  transition: 0.4s;
}

.lawyer-preview-container:hover .lawyer-preview-img {
  opacity: 0.9;
}

.lawyer-preview-h2 {
  color: var(--heading);
  cursor: pointer;
  font-size: 2rem;
  text-align: center;
  font-weight: 400;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: 0.4s;
}

.lawyer-preview-container:hover .lawyer-preview-h2 {
  background-color: var(--red);
  color: var(--white);
}

.legal-page {
  padding-top: 5rem;
}

.legal-page .about-para, .legal-page .dashboard-h1 {
text-align: center;
}

.lawyer-preview-p {
  color: var(--dark-gray);
  font-size: 1.3rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.center-team {
  display: flex;
  flex-direction: row;
  justify-self: center;
  justify-content: center;
}

.center-team .lawyer-preview-container {
  width: 22%;
  margin: 0 2.5rem;
}

.lawyer-text {
  margin-left: 15rem;
}

/* Animations */

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Buttons */

.red-button, .red-button-active {
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
  padding: 1.5rem 3rem;
  letter-spacing: 0.12rem;
  text-decoration: none;
  transition: 0.8s;
  text-align: center;
}

.red-button {
  background-color: var(--red);
}

.red-button-active, .red-button:hover {
  background-color: var(--dark-red);
}

.practice-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto 8rem;
}

.practice-buttons .red-button, .practice-buttons .red-button-active {
  width: 200px;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1.2rem 0;
  margin: 0 2.5rem;
}

/* Navigation */

nav {
  background-color: var(--dark-teal);
  width: 100dvw;
  padding: 0.4rem 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 27px;
  left: 0;
  transition: top 0.4s ease;
  z-index: 500;
}

.scrolled {
  top: 0;
}

.scrolled .logo {
  padding-bottom: 0;
}

.logo {
  cursor: pointer;
  padding-bottom: 1rem;
  width: 155px;
}

.nav-ul,
.small-ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
}

.small-ul {
  background-color: var(--small-nav);
  justify-content: flex-end;
  padding: 1.2rem 2rem 0 0;
}

.nav-icon {
  margin-right: 2rem;
}

.nav-ul li {
  margin-right: 3rem;
}

.small-ul li {
  margin-right: 4rem;
  color: var(--white);
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
}

.nav-small-link {
  color: var(--white);
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  text-decoration: none;
  transition: 0.8s;
}

.nav-small-link:hover {
  text-decoration: underline;
}

.nav-a,
.nav-link {
  color: var(--white);
  cursor: pointer;
  font-size: 1.35rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  text-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  transition: 0.8s;
}

.nav-a:hover,
.nav-link:hover {
  color: var(--light-red);
}

/* Homepage */

#about,
#news,
#contact, 
#notfound,
#disclaimer,
#copyright {
  width: 100dvw;
  padding: 8rem 5rem;
}

.about-copy,
.news-copy,
.contact-content {
  width: 80%;
  margin: 0 auto;
}

.about-para {
  color: var(--black);
  font-size: 1.6rem;
  margin-bottom: 3rem;
  line-height: 1.6;
}

/* Hero */

#hero {
  display: flex;
  align-items: center;
  width: 100dvw;
  padding: 10rem 5rem 5rem;
  height: calc(100dvh - 20px);
}

.hero-content, .legal-page {
  width: 70%;
  margin: 0 auto;
}

.hero-copy {
  margin-bottom: 1rem;
}

.hero-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.hero-arrow {
  height: 70dvh;
}

.news-hr {
  width: 100%;
  border: solid 0.8px var(--dark-gray);
}

.hero-h1 {
  color: var(--white);
  font-weight: 400;
  letter-spacing: 0.4rem;
  font-size: 9.6rem;
  line-height: 1.4;
  margin-top: 5rem;
  margin-bottom: 4rem;
  text-shadow: rgba(0, 0, 0, 0.76) 0px 3px 6px, rgba(0, 0, 0, 0.36) 0px 3px 6px;
  animation-duration: 1.8s;
  animation-delay: 1s;
}

.animated-span {
  animation-duration: 2s;
  animation-delay: 0.5s;
}

.fade {
  animation-duration: 2s;
  animation-delay: 2s;
}

.hero-h1-span {
  background-color: var(--red);
  padding: 0.5rem 2rem 0.2rem;
}

.hero-link {
  color: var(--white);
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  margin-bottom: 4rem;
  transition: 0.3s;
  text-shadow: rgba(0, 0, 0, 0.56) 0px 3px 6px, rgba(0, 0, 0, 0.26) 0px 3px 6px;
}

.hero-icon {
  margin-left: 1.5rem;
  font-size: 1.9rem;
  transition: transform 0.2s ease;
}

.hero-link:hover {
  color: var(--light-red);
}

.hero-link:hover .hero-icon {
  transform: translateX(6px);
}

/* About */

#about {
  background-color: var(--light-gray);
}

.about-copy {
  display: flex;
  flex-direction: row;
  animation-duration: 1.6s;
  animation-delay: 0.2s;
}

.about-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
}

.about-link {
  color: var(--heading);
  text-decoration: none;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
  transition: 0.5s;
}

.about-hr {
  width: 65%;
  margin-bottom: 4rem;
}

.about-right {
  width: 60%;
}

.about-icon {
  color: var(--red);
  margin-right: 3rem;
  transition: transform 0.5s ease;
}

.about-link:hover {
  color: var(--red);
}

.about-link:hover .about-icon {
  transform: translateX(8px);
}

/* News */

.news-header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px 150px;
  margin: 5rem auto 1rem;
}

.news-h2 {
  color: var(--dark-gray);
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;
}

.news-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0 2rem;
}

.news-icon {
  font-size: 1.5rem;
  color: var(--red);
  margin-right: 1rem;
}

.news-date {
  color: var(--dark-gray);
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.news-title {
  color: var(--teal);
  font-size: 1.8rem;
  font-weight: 500;
  transition: 0.6s;
  line-height: 1.5;
}

.news-link {
  text-decoration: none;
}

.news-link:hover .news-title {
  color: var(--dark-red);
}


/* Practice Area */

.practice-main-img {
  max-width: 100%;
}


.practice-area-text {
  font-size: 1.7rem;
  line-height: 1.6;
  margin-top: 4rem;
}


/* Contact Secondary Section */

.contact-secondary {
  align-items: center;
  background-color: var(--light-gray);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7rem;
  padding: 4rem 5rem;
  width: 100%;
}

.contact-secondary-h2 {
  color: var(--teal);
  font-size: 2.7rem;
  font-weight: 400;
}

.contact-secondary-text {
  color: var(--black);
  font-size: 1.7rem;
  line-height: 1.7;
  text-align: center;
  margin: 2rem auto 3rem;
}


/* Contact */

#contact .small-h2,
#contact .section-h1 {
  color: var(--white);
}

.contact-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  animation-duration: 1.6s;
  animation-delay: 0.2s;
}

.map-container {
  background-color: var(--white);
  width: 60%;
  height: 420px;
  padding: 0.5rem;
  animation-duration: 3s;
  animation-delay: 0.5s;
}

.contact-copy {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-para {
  color: var(--white);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.contact-link {
  color: var(--white);
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.05rem;
  margin: 1rem 0;
  transition: 0.8s;
}

.contact-link:hover {
  text-decoration: underline;
}

/* Team */

/* Insights */

/* Insights Pagination */

.pagination {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
}

.pagination-button {
  background-color: var(--white);
  color: var(--dark-gray);
  border: none;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 1.8rem;
  text-align: center;
  transition: 0.5s;
}

.pagination-button:hover, .active {
  color: var(--red);
  text-decoration: underline;
}

/* Insights Filter */

.filter-container {
  width: 100%;
  margin: 0 auto 8.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.filter-span,
.filter-button {
  color: var(--black);
  font-size: 1.6rem;
  font-weight: 400;
}

.filter-button {
  background-color: var(--white);
  border: none;
  cursor: pointer;
  margin: 0 1rem;
  transition: 0.6s;
  text-transform: capitalize;
}

.active {
  color: var(--red);
  text-decoration: underline;
}

.filter-button:hover {
  color: var(--red);
}

/* Insights Article Previews */

.article-preview-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10rem;
}

.article-preview-img {
  width: 45%;
  margin-right: 3rem;
}

.article-preview-h1 {
  color: var(--teal);
  cursor: pointer;
  margin: 3rem 0;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.5;
  transition: 0.6s;
}

.article-preview-h1:hover {
  color: var(--red);
}

.article-preview-h2 {
  color: var(--dark-teal);
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;
}

.article-preview-link {
  color: var(--dark-teal);
  cursor: pointer;
  font-size: 1.4rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.5s;
}

.article-preview-link:hover {
  color: var(--dark-red);
}

.article-preview-link .about-icon {
  margin-left: 1.6rem;
  transition: 0.5s;
}

.article-preview-link:hover .about-icon {
  margin-left: 2rem;
}

/* Article /:id */

.article-h2 {
  color: var(--dark-gray);
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  margin-top: 12rem;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
}

.article-h1 {
  color: var(--heading);
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  width: 90%;
  margin: 0 auto 2rem;
}

.article-img {
  object-fit: cover; 
  width: 100%;
  margin-bottom: 5rem;
}

.article-para {
  color: var(--black);
  font-size: 1.7rem;
  line-height: 1.7;
  margin-bottom: 4rem;
}

.article-social-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.social-link {
  color: var(--heading);
  cursor: pointer;
  font-size: 1.8rem;
  margin-left: 2rem;
  transition: 0.5s;
}

.social-link:hover {
  color: var(--teal);
}

/* Footer */

footer {
  background-color: var(--footer);
  width: 100dvw;
  padding: 5rem 2rem 3rem;
}

.footer-link-container {
  display: flex;
  flex-direction: row;
}

.footer-ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0.5rem 3rem;
  transition: top 0.4s ease;
}

.footer-ul li {
  margin-bottom: 0.8rem;
}

.footer-a,
.footer-link {
  color: var(--white);
  cursor: pointer;
  font-size: 1.35rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  transition: 0.8s;
}

.footer-a:hover,
.footer-link:hover {
  color: var(--light-red);
}

.footer-logo-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0 5rem;
}

.footer-text {
  color: var(--white);
  font-size: 1.5rem;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.footer-logo {
  cursor: pointer;
  margin-left: auto;
  width: 135px;
}

/* LAWYER */

#lawyer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 10rem;
  padding-top: 8rem;
  width: 100dvw;
  background-image: url('/src/assets/insights-banner.png');
  background-size: 100% 325px;
  background-repeat: no-repeat;
}

#lawyer hr {
  margin-bottom: 4rem;
}

.lawyer-img {
  width: 300px;
  margin-bottom: 1rem;
}

.lawyer-staff, .lawyer-bio {
  margin-top: 3rem;
}

.lawyer-staff {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.lawyer-bio {
  width: calc(100% - 300px);
  font-size: 1.4rem;
  margin-left: 3rem;
  line-height: 1.5;
}

.assistant {
  width: 100%;
  margin-bottom: 3.5rem;
}

.staff-text, .staff-text-link {
  font-size: 1.5rem;
  line-height: 1.5;
}

.lawyer-section {
  display: flex;
  flex-direction: row;
  width: 65%;
}

.lawyer-subsection,   .lawyer-subsection-details {
  display: flex;
  flex-direction: column;
}

.lawyer-desc {
  font-size: 1.7rem;
}

.lawyer-h1 {
  color: var(--white);
  font-size: 3.5rem;
  margin-bottom: 2rem;
  font-weight: 400;
}

.lawyer-title {
    color: var(--white);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    margin-bottom: 6rem;
}

.lawyer-contact-link {
    color: var(--white);
    display: block;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 0.05rem;
    margin: 1rem 0 1.5rem;
    transition: 0.8s;
  }
  
  .lawyer-contact-link:hover {
    text-decoration: underline;
  }

.red-span {
  background-color: var(--red);
  padding: 0.5rem 2rem;
}


.lawyer-practice-section {
  margin-top: 10rem;
}

.lawyer-h2 {
  color: var(--heading);
  font-size: 2.3rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.lawyer-ul {
  list-style: none;
}

.lawyer-li {
  font-size: 1.7rem;
  margin-bottom: 0.5rem;
}

/* 404 */

#notfound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 90dvh;
}

.not-found-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found-h1 {
  color: var(--heading);
  font-size: 5rem;
  margin-bottom: 3rem;
}

.not-found-para {
  font-size: 1.8rem;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 2rem;
}

/* ADMIN PAGES */

/* LOGIN */

#login {
  background-color: var(--light-gray);
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80dvh;
  width: 100dvw;
}

.login-container {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding: 4rem 4rem 3rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.login-form input {
  font-size: 1.5rem;
  padding: 0.5rem;
  margin-top: 2rem;
}

.login-button {
  background-color: var(--red);
  border: none;
  color: var(--white);
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  margin-top: 2rem;
  padding: 0.7rem 0;
  transition: 0.5s;
}

.login-button:hover {
  background-color: var(--dark-red);
}

.logout-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6rem;
}

#dashboard {
  margin-top: 50px;
}

.dashboard-h1 {
  text-align: center;
  color: var(--heading);
  font-size: 3rem;
  margin-bottom: 4rem;
}

.dash-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.option-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 45%;
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  transition: 0.4s;
}

.option-2-container {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  cursor: pointer;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: 0.4s;
}

.option-container:hover, .option-2-container:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.option-h1 {
font-size: 2rem;
color: var(--teal);
}

.option-p {
  font-size: 1.5rem;
  text-align: center;
  margin: 2rem auto;
  line-height: 1.5;
}

.message {
  color: var(--white);
  font-size: 1.8rem;
  text-align: center;
}

#admin-team {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-bar {
  border: 1px solid var(--dark-gray);
  border-radius: 3px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  padding: 1rem;
}

.search-input {
  border: none;
  font-size: 1.5rem;
  width: 100%;
}

.search-input:focus, .search-input:active {
  border: none;
  outline: none;
}

.search-button {
  background-color: var(--white);
  border: none;
  font-size: 1.8rem;
}

.red-button-admin {
margin: 0 auto;
background-color: var(--red);
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
  padding: 1.5rem 3rem;
  letter-spacing: 0.12rem;
  text-decoration: none;
  transition: 0.8s;
  text-align: center;
  width: 240px;
}

.red-button-admin:hover {
  background-color: var(--dark-red);
}


.admin-item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.admin-item-name {
  color: var(--teal);
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 500;
  transition: 0.4s;
}

.admin-item-name:hover {
  color: var(--heading);
}

.admin-icon {
  cursor: pointer;
  font-size: 1.8rem;
  margin-left: 2rem;
}

.admin-icon:hover {
  color: var(--teal);
}

.admin-hr {
  width: 100%;
  margin: 2rem auto;
}

.team-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}

.form-input, .form-input-desc {
  color: var(--black);
  font-size: 1.8rem;
  padding: 0.7rem 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

.form-input-desc {
  word-wrap: break-word;
}

textarea {
  font-family: "Inter", sans-serif;
  word-wrap: break-word;
  width: 95%;
  }

.upload {
  width: 150px;
  margin-bottom: 2rem;
}

.x {
  background-color: var(--white);
  border: none;
  color: var(--dark-red);
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 600;
  margin-left: 0.6rem;
  margin-right: 2rem;
}

.add {
  margin-bottom: 2rem;
  width: 100px;
}

.staff {
  margin-bottom: 5rem;
  width: 200px;
}

.staff-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
}

.form-option {
  color: var(--black);
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.form-ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  margin-bottom: 2rem;
}

.form-h2 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.upload-text {
  display: flex;
  color: var(--dark-gray);
  font-size: 1.8rem;
  flex-direction: row;
  margin-bottom: 2rem;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-button {
  background-color: var(--white);
  color: var(--dark-red);
  font-size: 1.7rem;
  border: none;
  cursor: pointer;
}

.custom-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.select-container {
margin-bottom: 3rem;
}

.article-label {
  font-size: 1.5rem;
  font-weight: 500;
  margin-left: 1rem;
}

input[type="radio"] {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid #000; /* Border color */
  background-color: #fff; /* Background color */
  border-radius: 50%;
}

.ql-editor {
  font-size: 1.7rem;
  min-height: 550px; 
}

.admin-icon-home {
  color: var(--black);
  cursor: pointer;
  font-size: 2.5rem;
}

.admin-icon-home:hover {
  color: var(--teal);
}

.hours-form {
  display: flex;
  flex-direction: column;
  width: 600px;
}

.header-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.header-item {
  margin-bottom: 3rem;
  width: 100%;
}

.header-name {
  color: var(--teal);
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */

@media screen and (min-width: 1800px) {
  .hero-h1 {
    font-size: 12rem;
  }
}

@media screen and (min-width: 1559px) {
  
  .hero-link {
    font-size: 2rem;
  }
  
  .secondary-p {
    font-size: 2.8rem;
  }
  
  .nav-a, .nav-link, .footer-link, .footer-a, .footer-text {
    font-size: 1.8rem;
  }
  
  .small-ul {
    padding: 1.5rem 4rem 1.9rem;
  }
  
  .small-ul li, .nav-small-link {
    font-size: 1.8rem;
  }
  
  nav {
    top: 34px;
    padding: 2rem 5rem;
  }
  
  #about, #news, #contact, #notfound, #disclaimer, #copyright, #dashboard, #admin-edit, #hours-edit, #admin-team-edit, #headers-edit, #article-edit, #admin-article-edit {
    padding: 18rem 5rem;
  }
  
  .about-link, .news-title, .lawyer-preview-h2, .article-preview-h1 {
    font-size: 2.5rem;
  }
  
  .about-link {
    margin-bottom: 3rem;
  }
  
  .small-h2, .news-h2, .news-date, .lawyer-preview-p, .article-preview-h2, .article-preview-link, .staff-text, .staff-text-link, .lawyer-contact-link, .lawyer-title, .article-h2, .lawyer-li {
    font-size: 1.8rem;
  }
  
  .section-h1 {
    font-size: 4rem;
    margin-bottom: 4rem;
  }

  .access-h1 {
    font-size: 2rem
  }

  .access-h2 {
    font-size: 2rem;
  }
  
  .about-para, .access-h4, .contact-para, .contact-link, .secondary-h1, .practice-area-text, .contact-secondary-text, .lawyer-desc, .article-para, .filter-button {
    font-size: 1.9rem;
  }
  
  .red-button {
    font-size: 1.5rem;
  }
  
  .footer-logo, .logo {
    width: 200px;
  }
  
  .banner-icon {
    font-size: 2.5rem;
  }
  
  .secondary-h1 {
    margin-bottom: 4rem;
  }
  
  .contact-secondary-h2 {
    font-size: 3rem;
  }
  
  #lawyer, #article {
    margin-top: 10rem;
  }
  
  .article-h1 {
    margin-bottom: 5rem;
  }
  
  .map-container {
    height: 600px;
  }
  
}


/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */

@media screen and (max-width: 1556px) {
.small-ul {
  padding: 1.5rem 4rem;
}

.hero-h1 {
  margin-top: 2rem;
}


nav {
  padding: 1rem 5rem 0.5rem;
  top: 34px;
}
}

/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */

@media screen and (max-width: 1328px) {
.news-grid {
  grid-gap: 50px 70px;
}

.staff-text, .staff-text-link {
font-size: 1.6rem;
}

.hero-h1 {
  margin-top: 1rem;
}


.news-h2, .news-date {
  font-size: 1.2rem;
}

.secondary-banner {
  height: 400px;
}
}


@media screen and (max-width: 1220px) {
  .hero-h1 {
    font-size: 8rem;
  }
}

/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */

@media only screen and (max-device-width: 1050px) and (min-device-height: 900px) {
  #hero {
    height: 60dvh;
  }
}

/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */

@media screen and (max-width: 1161px) {
  .small-ul {
    padding: 1.4rem 2rem;
  }

  .about-copy, .news-copy, .contact-content {
    width: 90%;
  }

  .about-link {
    font-size: 1.8rem;
  }

  #team, #insights, #practiceone, #practicetwo, #practicethree, #article, #dashboard, #admin-team, #admin-team-edit, #admin-edit, #article-edit, #hours-edit, #headers-edit, #admin-article-edit, #disclaimer, #copyright {
    width: 80%
  }
}

/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */

@media screen and (max-width: 1190px) {

  .hero-content {
    width: 80%;
  }

  .about-copy, .news-copy, .contact-content {
    width: 85%;
  }

  #team, #insights, #practiceone, #practicetwo, #practicethree, #article, #dashboard, #admin-team, #admin-team-edit, #admin-edit, #article-edit, #hours-edit, #headers-edit, #admin-article-edit, #disclaimer, #copyright {
    width: 80%
  }

  .lawyer-section {
    width: 75%;
  }

  .lawyer-text {
    margin-left: 10rem;
  }
}

/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */

@media screen and (max-width: 1160px) {

  .small-ul {
    padding: 1.4rem 2rem;
  }

  nav {
    padding: 1rem 2rem 0.5rem;
  }

  .lawyer-h1 {
    font-size: 3rem;
  }

  .lawyer-li {
    font-size: 1.6rem;
  }

  .lawyer-contact-link {
    font-weight: 400;
  }

  .secondary-p {
    font-size: 2rem;
    width: 65%;
  }

  .hero-h1 {
    font-size: 8rem;
  }
}

/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */

@media screen and (max-width: 1080px) {
  .nav-a, .nav-link, .footer-link, .footer-a, .footer-text {
    font-size: 1.2rem;
  }

  .map-container {
    height: 380px;
  }

  .section-h1 {
    font-size: 2.8rem;
  }

  .lawyer-desc {
    font-size: 1.6rem;
  }

  .article-h1 {
    font-size: 2.7rem;
  }

  .legal-page {
    width: 80%;
  }
}

/* Media Query */
/* Media Query */
/* Media Query */
/* Media Query */
/* HAMBURGER */
/* HAMBURGER */
/* HAMBURGER */
/* HAMBURGER */
/* HAMBURGER */

@media screen and (max-width: 1015px) {
  .small-ul {
    display: none;
  }

  nav {
    top: 0;
    padding: 0.3rem 2rem;
  }

  .hero-h1 {
    font-size: 7.5rem;
  }

  .access-h1 {
    font-size: 1.7rem
  }

  .access-h2 {
    font-size: 1.7rem;
  }
  

  .about-para, .access-h4 {
    font-size: 1.5rem;
  }

  .about-link {
    font-size: 1.7rem;
  }

  .about-copy, .news-copy, .contact-content {
    width: 90%;
  }

  .hero-link {
    font-size: 1.4rem;
  }

  .logo {
    margin-top: 0.4rem;
    width: 130px;
  }

  .break {
    display: block;
    margin-bottom: 1rem;
  }

  .contact-secondary-text {
    line-height: 1.5;
  }

  .contact-secondary br {
    display: none;
  }

  .navbar-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .nav-ul {
    display: none;
  }

  .ham-link {
    display: block;
  }

  .nav-ul-hamburger {
    display: flex;
    flex-direction: column;
    background-color: var(--dark-teal);
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    height: 100dvh;
    padding: 5rem 2rem 2rem;
    align-items: center;
    list-style: none;
  }

  .nav-ul-hamburger li {
    margin-bottom: 3rem;
  }

  .nav-link, .nav-a {
    font-size: 1.5rem;
  }
}



@media screen and (max-width: 980px) {
  .about-link {
    font-size: 1.6rem;
  }

  .map-container {
    height: 340px;
  }

  .practice-area-text, .contact-secondary-text, .article-para {
    font-size: 1.6rem;
  }

  .secondary-p {
    font-size: 1.8rem;
  }

  .article-h1 {
    font-size: 2.5rem;
  }

  .logo {
    width: 140px;
  }

  .news-title {
    font-size: 1.6rem;
  }

  .contact-secondary-h2 {
    font-size: 2.5rem;
  }

  .contact-secondary-text {
    line-height: 1.6;
  }


  .lawyer-li, .lawyer-desc, .staff-text, .staff-text-link {
    font-size: 1.5rem;
  }

  .lawyer-contact-link {
    font-size: 1.5rem;
  }

  .lawyer-img {
    width: 250px;
  }

  .lawyer-h1 {
    font-size: 2.8rem;
  }

  .contact-para {
    margin-bottom: 0.8rem;
  }

  .contact-link {
    font-weight: 400;
    font-size: 1.4rem;
  }

  .section-h1 {
    font-size: 2.6rem;
  }

  .new-h2, .news-date {
    font-size: 1.1rem;
  }

  .article-preview-h2 {
    font-size: 1.3rem;
  }

  .secondary-h1 {
    font-weight: 400;
    font-size: 1.4rem;

  }

  .lawyer-h2 {
    font-size: 2.2rem;
  }

  .lawyer-h1 {
    font-weight: 300;
  }

  .small-h2 {
  font-size: 1.3rem;
  }

  .about-right {
    width: 68%;
    margin-left: 2rem;
  }

  .about-left {
    width: 30%;
  }

  .about-link {
    font-size: 1.5rem;
  }

  .about-hr {
    width: 90%;
  }

  #about {
    padding-left: 4rem;
    padding-right: 4rem;
  }

}

@media screen and (max-width: 950px) {

  .about-copy {
    flex-direction: column;
  }

  .hero-h1 {
    font-size: 6rem;
  }

  .about-left {
    order: 2;
    width: 100%;
  }

  .about-right {
    order: 1;
    width: 100%;
    margin-left: 0;
    margin-bottom: 4rem;
  }

  .about-right .red-button {
    display: none;
  }

  .article-preview-h1 {
    font-size: 1.8rem;
  }

  .map-container {
    height: 320px;
    animation-duration: 1s;
    animation-delay: 0s;
  }

  .small-h2 {
    font-size: 1.2rem;
  }

  .team-form {
    width: 80%;
  }

  .about-hr {
    width: 100%;
  }
}

@media screen and (max-width: 870px) {

  .section-h1 {
    font-size: 2.4rem;
  }

  .about-link {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 850px) {
  .news-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 70px 70px;
    margin-top: 3rem;
  }

  .contact-content {
    flex-direction: column;
  }

  .map-container, .contact-copy {
    width: 100%;
  }

  .contact-copy {
    text-align: center;
    margin-top: 2rem;
  }

  .map-container {
    margin-bottom: 3rem;
  }

  .secondary-h1 {
    font-size: 1.2rem;
  }

  .red-button {
    font-size: 1.1rem;
  }

  .practice-area-text, .contact-secondary-text, .article-para {
    font-size: 1.5rem;
  }

  .secondary-p {
    font-size: 1.7rem;
    line-height: 3rem;
  }

  .team-grid {
    grid-gap: 40px 30px;
  }

  .lawyer-preview-p {
    font-size: 1.2rem;
  }

  .lawyer-preview-h2 {
    font-size: 1.8rem;
  }

  .article-preview-h2 {
    font-size: 1.2rem;
  }

  .lawyer-title {
    font-size: 1.2rem;
  }

  .lawyer-h1 {
    font-size: 2.6rem;
  }

  .lawyer-contact-link {
    font-size: 1.3rem;
  }

  .lawyer-h2 {
    font-size: 2rem;
  }

  .staff-text, .staff-text-link {
    font-size: 1.4rem;
  }

  .lawyer-li {
    font-size: 1.4rem;
  }

  .lawyer-desc {
    font-size: 1.4rem;
  }

  .article-h1 {
    font-size: 2.1rem;
  }

  .article-h2 {
    font-size: 1.2rem;
  }

  .dashboard-h1 {
    font-size: 2.4rem;
  }

  .option-h1 {
    font-size: 1.8rem;
  }

  .hero-link {
    font-size: 1.2rem;
  }

}

@media screen and (max-width: 770px) {
.logo {
  width: 120px;
}

.small-h2 {
  margin-bottom: 1.5rem;
}

.about-link {
  font-size: 1.7rem;
}

.about-right {
  margin-bottom: 2rem;
}

.practice-buttons {
  margin-bottom: 4rem;
}

.contact-secondary {
  padding: 3rem;
}

.contact-secondary-h2 {
  font-size: 2.3rem;
}

.red-button, .practice-buttons .red-button, .practice-buttons .red-button-active  {
  font-weight: 500;
  font-size: 1.2rem;
}

.lawyer-text {
  margin-left: 5rem;
}

.nav-ul-hamburger {
  top: 50px;
}

.lawyer-h1 {
  font-size: 2.5rem;
}

.lawyer-title {
  font-weight: 400;
}

.filter-button {
  font-size: 1.5rem;
}

.article-preview-img {
  width: 50%;
}

#insights {
  width: 85%;
}

.article-preview-h1 {
  font-size: 1.6rem;
}

.article-preview-h2 {
  font-size: 1.1rem;
}

.article-preview-link {
  font-size: 1.3rem;
}

#article {
  padding-top: 1rem;
}
}

@media screen and (max-width: 730px) {
  .lawyer-section {
    flex-direction: column;
    width: 85%;
    justify-content: center;
    align-items: center;
  }

  .lawyer-h1 {
    color: var(--heading);
    margin-bottom: 2rem;
    font-size: 3rem;
  }

  .lawyer-title {
    margin-bottom: 3rem;
  }

  .lawyer-text {
    width: 100%;
    margin-left: 0;
  }

  .lawyer-subsection-details {
    width: 100%;
  }

  .lawyer-subsection .red-button {
    display: none;
  }

  .lawyer-contact-link {
    color: var(--heading);
    text-align: center;
    font-weight: 600;
    font-size: 1.4rem;
  }

  .lawyer-practice-section {
    margin-top: 5rem;
  }

  .lawyer-h2 {
    text-align: center;
    font-size: 2.1rem;
  }

  .lawyer-li, .lawyer-desc, .staff-text, .staff-text-link {
    font-size: 1.5rem;
  }

  .lawyer-ul {
    text-align: center;
    margin-top: 3rem;
  }

  .lawyer-section .lawyer-staff {
    order: 2;
    width: 100%;
  }

  .lawyer-section .lawyer-bio {
    order: 1;
    width: 100%;
    margin-left: 0;
  }

  .lawyer-staff .lawyer-h2 {
    text-align: left;
  }

  .lawyer-bio .red-button {
    display: block;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  #lawyer {
    padding-top: 6rem;
  }

  .red-button .download-one {
    display: none;
  }

  .lawyer-title {
    text-align: center;
  }

  .lawyer-h1 {
    text-align: center;
    font-size: 2.8rem;
  }

  .lawyer-subsection {
    width: 300px
  }

  .lawyer-subsection img {
    width: 300px
  }

  #team, #insights, #practiceone, #practicetwo, #practicethree, #article, #dashboard, #admin-team, #admin-team-edit, #admin-edit, #article-edit, #hours-edit, #headers-edit, #admin-article-edit, #disclaimer, #copyright {
    width: 90%;
  }
}

@media screen and (max-width: 697px) {
  .hero-h1 {
    font-size: 5rem;
      margin-bottom: 3rem;
      text-shadow: rgba(0, 0, 0, 0.56) 0px 3px 6px, rgba(0, 0, 0, 0.26) 0px 3px 6px;
  }

  .about-copy, .news-copy, .contact-copy {
    width: 95%;
  }

  .news-h2, .news-date {
    font-size: 1.1rem;
  }

  .access-h1 {
    font-size: 1.6rem
  }

  .access-h2 {
    font-size: 1.6rem;
  }
  

  .about-para, .access-h4, .contact-para, .contact-link, .practice-area-text, .contact-secondary-text, .article-para {
    font-size: 1.4rem;
  }

  .red-button, .practice-buttons .red-button, .practice-buttons .red-button-active {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    font-size: 1.1rem;
  }

  .news-grid {
    grid-gap: 65px 50px;
  }

  .contact-secondary {
    margin-top: 5rem;
  }

  .contact-secondary-h2 {
    font-size: 2.2rem;
  }

  .lawyer-preview-h2 {
    font-size: 1.6rem;
}

.lawyer-preview-p, .article-preview-h2 {
  font-size: 1.1rem;
}

.article-preview-h1 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.article-preview-h2 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.article-h1 {
  font-size: 2rem;
}

.article-h2 {
  font-size: 1.1rem;
  margin-top: 10rem;
}
}

@media screen and (max-width: 640px) {
  .footer-ul {
    display: none;
  }

  .footer-logo {
    display: none;
  }

  .filter-button, .filter-span {
    font-size: 1.4rem;
    text-align: center;
  }
}

@media screen and (max-width: 611px) {
  .hero-content {
    width: 90%;
  }

  .about-left {
    margin-top: 1rem;
  }

  .contact-secondary-text {
   line-height: 1.5;
  }
}

@media screen and (max-width: 600px) {
  .news-grid {
    grid-template-columns: 1fr;
    grid-gap: 50px 50px;
  }

  .practice-buttons .red-button, .practice-buttons .red-button-active {
    width: 200px;
  }

  .contact-secondary {
    padding: 2rem 3rem;
  }
  .lawyer-preview-p, .article-preview-h2 {
    line-height: 1.5;
}

.search-bar {
  width: 90%;
}

.lawyer-h2 {
  text-align: left;
}


.lawyer-ul {
  text-align: left;
}

.lawyer-h1, .lawyer-title, .lawyer-contact-link {
  text-align: left;
}

.lawyer-section {
  align-items: flex-start;
  justify-content: flex-start;
}

.lawyer-subsection img {
  margin-bottom: 3rem;
}

}

@media screen and (max-width: 553px) {
  .hero-content {
    width: 95%;
  }

  .hero-copy {
    width: 100%;
  }

  .hero-link {
    text-shadow: rgba(0, 0, 0, 0.36) 0px 3px 6px, rgba(0, 0, 0, 0.16) 0px 3px 6px;
  }

  .filter-button {
    margin-bottom: 1.5rem;
  }

  .filter-container {
    margin-bottom: 4rem;
  }

  .secondary-p {
    width: 85%;
  }
}

@media screen and (max-width: 550px) {
.article-preview-container {
  flex-direction: column;
  margin-bottom: 6rem;
}

.article-preview-img {
  width: 100%;
}

.article-preview-copy {
  margin-top: 3rem;
}

.article-preview-h2 {
  font-size: 1.1rem;
}

.article-preview-h1 {
  font-size: 1.7rem;
}

.team-grid {
  grid-template-columns: 1fr;
  grid-gap: 50px;
}

.center-team {
  flex-direction: column;
  width: 100%;
}

.center-team .lawyer-preview-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 50px;
}

.lawyer-preview-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lawyer-preview-container img {
  width: 40%;
}

.lawyer-preview-h2 {
  font-size: 1.7rem;
}
}

@media screen and (max-width: 529px) {
  .hero-h1 {
    font-size: 4.5rem;
  }

  .news-header-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .news-copy .red-button {
    display: block;
    margin-top: 5rem;
  }

  .footer-text {
    text-align: center;
  }
  
  .news-copy .news-header-section .red-button {
    display: none;
  }

  .map-container {
    height: 300px;
  }

  #about, #news, #contact, #notfound, #disclaimer, #copyright {
    padding: 8rem 2rem;
}

.contact-content, .contact-copy {
  align-items: flex-start;
  width: 90%;
  text-align: left;
}

.contact-copy div {
  width: 100%;
}
}

@media screen and (max-width: 529px) {
  .lawyer-preview-container img {
    width: 50%;
  }
  
  /* .practice-buttons {
    flex-direction: column;
  }

  .practice-buttons .red-button, .practice-buttons .red-button-active {
   margin-bottom: 1rem;
   width: 50%;
} */

.practice-buttons {
  width: 100%;
}

.practice-buttons .red-button, .practice-buttons .red-button-active {
 margin-bottom: 1rem;
 width: 30%;
}
}

@media screen and (max-width: 500px) {
.practice-buttons .red-button, .practice-buttons .red-button-active {
  margin: 0 0.5rem;
}

.lawyer-preview-container:hover .lawyer-preview-h2 {
  background-color: var(--white);
  color: var(--heading);
}

.secondary-banner {
  height: 300px;
}
}

@media screen and (max-width: 480px) {
  .dash-container {
    flex-direction: column;
  }

  .option-container {
    width: 100%;
    margin-bottom: 2rem;
  }

  .admin-icon {
    font-size: 1.5rem;
  }

}

@media screen and (max-width: 365px) {
  .hero-h1 {
    font-size: 4rem;
  }

#hero {
    padding: 10rem 1rem 5rem;
  }

  .hero-content {
    width: 90%;
}
}


@media only screen and (max-device-width: 500px) and (min-device-height: 500px) {
  #hero {
    height: 75dvh;
  }

}

@media only screen and (max-device-width: 500px) and (min-device-height: 450px) {
  .lawyer-subsection img, .lawyer-subsection {
    width: 100%;
}
}